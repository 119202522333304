import axios from '../libs/axios';

// 会费类型列表
const feeList = (data) => {
  return axios.get('/api/trd/fee/list', data);
};
// 会费信息
const feeById = (id) => {
  return axios.get(`/api/trd/fee/info/${id}`);
};
// 会费类型保存/修改
const feeSubmit = (data) => {
  return axios.post('/api/trd/fee/save', data);
};

// 会费类型删除
const deleteFeeById = (id) => {
  return axios.get(`/api/trd/fee/delete/${id}`);
};
// 启用或者禁用
const feeStatus = (data) => {
  return axios.post(`/api/trd/fee/status/${data.feeId}/${data.status}`);
};
// 提交充值规则
const feeRechargeSubmit = (data) => {
  return axios.post('/api/trd/recharge/fee/save', data);
};
// 次卡充值列表
const feeRechargeList = (data) => {
  return axios.get('/api/trd/recharge/fee/list', data);
};
export {
  feeList,
  feeById,
  feeSubmit,
  deleteFeeById,
  feeStatus,
  feeRechargeSubmit,
  feeRechargeList
};
