<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit"
    >
      <div slot="content">
        <a-card title="会费信息">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="会费名称">
                  <a-input v-model="form.feeName" :maxLength="50" allowClear :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="会费价格" prop="price">
                  <a-input-number
                    placeholder="请输入会费价格"
                    v-model="form.price"
                    size="large"
                    :maxLength="10"
                    :min="0"
                    :max="9999999999"
                    allowClear
                  />
                  <span class="ml-20">
                        元
                      </span>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="折扣率" prop="discount">
                  <a-input-number
                    placeholder="请输入折扣率"
                    v-model="form.discount"
                    size="large"
                    :min="0"
                    step="0.01"
                    :max="1"
                    allowClear
                  />
                  <span class="ml-20">
                        范围(0~1) <span style="color: red">注意：选0则为通用0元卡</span>
                      </span>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="有效类型" prop="periodType">
                  <a-select
                    v-model="form.periodType"
                    allowClear
                  >
                    <a-select-option :value="-1"> 年卡 </a-select-option>
                    <a-select-option :value="-2"> 季卡 </a-select-option>
                    <a-select-option :value="-3"> 月卡 </a-select-option>
                    <a-select-option :value="3"> 自定义 </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row v-if="form.periodType === 3">
              <a-col :span="10">
                <a-form-model-item label="自定义天数" prop="days">
                  <a-input-number
                    placeholder="请输入自定义天数"
                    v-model="form.days"
                    size="large"
                    :maxLength="10"
                    :min="1"
                    :max="9999999999"
                    allowClear
                  />
                  <span class="ml-20">
                        天
                      </span>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { feeById, feeSubmit } from '@/api/fee';
import FormFrame from '@/components/frame/FormFrame.vue';
import formMixin from '@/mixin/formMixin';

export default {
  name: 'feePriceForm',
  mixins: [formMixin],
  components: {
    FormFrame,
  },
  created() {
    if (this.paramsId) {
      this.getData(this.paramsId);
    }
  },
  data() {
    return {
      form: {
        days: 1,
        discount: 1,
        feeName: '',
        price: 0,
        periodType: -1,
      },
      rules: {
        price: [
          {
            required: true,
            message: '请正确输入价格',
          }
        ],
      },
    };
  },
  methods: {
    // 信息
    getData(id) {
      feeById(id).then((data) => {
        this.form = data;
      });
    },
    // 保存
    handleSave() {
      const params = { ...this.form };
      console.log(params);
      if (params.periodType < 0) {
        params.days = params.periodType;
      }
      // console.log(params);
      feeSubmit(params).then(() => {
        this.$message.success('操作成功！');
        this.handleBack();
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleSave();
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
